@tailwind base;
@tailwind components;
@tailwind utilities;
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
@layer components{
    .input{
        @apply block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-black  focus:outline-none focus:ring-0 focus:border-black
    }
    .input_label{
        @apply peer-focus:font-medium absolute text-sm text-black dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6;
    }
}